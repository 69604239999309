import React from 'react';
import MuiLinearProgress, { LinearProgressProps as MuiLinearProgressProps } from '@mui/material/LinearProgress';

type LinearProgressProps = Omit<MuiLinearProgressProps, 'classes' | 'sx'>;

/**
 * Renders a MUI LinearProgress component.
 *
 * - [LinearProgress documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/402292936/Progress)
 * - [API documentation](https://mui.com/material-ui/api/linear-progress/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const LinearProgress = React.forwardRef<HTMLDivElement, LinearProgressProps>(({ ...props }: LinearProgressProps, ref) => {
    return <MuiLinearProgress ref={ref} {...props} />;
});

LinearProgress.displayName = 'LinearProgress';

export default LinearProgress;
