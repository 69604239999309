import React from 'react';
import MuiAlertTitle, { AlertTitleProps as MuiAlertTitleProps } from '@mui/material/AlertTitle';

type AlertTitleProps = Omit<MuiAlertTitleProps, 'classes' | 'sx'>;

/**
 * Renders a MUI AlertTitle component.
 *
 * - [MUI AlertTitle documentation](https://mui.com/material-ui/api/alert-title/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const AlertTitle = React.forwardRef<HTMLDivElement, AlertTitleProps>(({ children, ...props }: AlertTitleProps, ref) => {
    return (
        <MuiAlertTitle ref={ref} {...props}>
            {children}
        </MuiAlertTitle>
    );
});

AlertTitle.displayName = 'AlertTitle';

export default AlertTitle;
