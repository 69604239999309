import React from 'react';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

type TextFieldProps = Omit<MuiTextFieldProps, 'classes' | 'sx'>;

/**
 * Renders a MUI TextField component.
 *
 * - [TextField documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/402292737/TextField)
 * - [API documentation](https://mui.com/material-ui/api/text-field/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(({ ...props }: TextFieldProps, ref) => {
    return <MuiTextField ref={ref} {...props} />;
});

TextField.displayName = 'TextField';

export default TextField;
