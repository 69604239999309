import React from 'react';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { checkedIcon, icon } from './icons/radio-icons';

type RadioProps = Omit<MuiRadioProps, 'classes' | 'sx' | 'icon' | 'checkedIcon'>;

/**
 * Renders a MUI Radio component.
 *
 * - [Radio documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/338100226/Radio)
 * - [API documentation](https://mui.com/material-ui/api/radio/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Radio = React.forwardRef<HTMLButtonElement, RadioProps>(({ ...props }: RadioProps, ref) => {
    return <MuiRadio ref={ref} icon={icon} checkedIcon={checkedIcon} {...props} />;
});

Radio.displayName = 'Radio';

export default Radio;
