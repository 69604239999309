import React from 'react';
import { SvgIcon } from '@mui/material';

export const checkedIcon = (
    <SvgIcon className="ui-v2-radio__checked-icon">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_i_126_25734)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21.8182C17.4224 21.8182 21.8182 17.4224 21.8182 12C21.8182 6.57757 17.4224 2.18182 12 2.18182C6.57757 2.18182 2.18182 6.57757 2.18182 12C2.18182 17.4224 6.57757 21.8182 12 21.8182ZM12 15.2727C13.8075 15.2727 15.2727 13.8075 15.2727 12C15.2727 10.1925 13.8075 8.72727 12 8.72727C10.1925 8.72727 8.72729 10.1925 8.72729 12C8.72729 13.8075 10.1925 15.2727 12 15.2727Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <filter
                    id="filter0_i_126_25734"
                    x="2.18182"
                    y="2.18182"
                    width="19.6364"
                    height="19.6364"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_126_25734" />
                </filter>
            </defs>
        </svg>
    </SvgIcon>
);

export const icon = (
    <SvgIcon className="ui-v2-radio__icon">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.4813 16.5187 3.81818 12 3.81818C7.48131 3.81818 3.81819 7.4813 3.81819 12C3.81819 16.5187 7.48131 20.1818 12 20.1818ZM12 21.8182C17.4224 21.8182 21.8182 17.4224 21.8182 12C21.8182 6.57757 17.4224 2.18182 12 2.18182C6.57757 2.18182 2.18182 6.57757 2.18182 12C2.18182 17.4224 6.57757 21.8182 12 21.8182Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
