import React from 'react';
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

export type AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined = undefined
> = Omit<MuiAutocompleteProps<T | string, Multiple, DisableClearable, FreeSolo>, 'classes' | 'sx'>;

const Autocomplete: React.FC<AutocompleteProps<any, boolean, boolean, boolean>> = ({ ...props }) => {
    return <MuiAutocomplete {...props} />;
};

export default Autocomplete;
