import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

export type ButtonProps = Omit<MuiButtonProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Button component.
 *
 * - [Button documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/311263235/Button)
 * - [API documentation](https://mui.com/material-ui/api/button/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }: ButtonProps, ref) => {
    return (
        <MuiButton ref={ref} {...props}>
            {children}
        </MuiButton>
    );
});

Button.displayName = 'Button';

export default Button;
