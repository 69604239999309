import React from 'react';
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';

type MenuItemProps = Omit<MuiMenuItemProps, 'classes' | 'sx'> & {
    href?: string;
};

/**
 * Renders a MUI MenuItem component.
 *
 * - [MUI MenuItem documentation](https://mui.com/material-ui/api/menu-item/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const MenuItem = React.forwardRef<HTMLLIElement, MenuItemProps>(({ children, ...props }: MenuItemProps, ref) => {
    return (
        <MuiMenuItem ref={ref} {...props}>
            {children}
        </MuiMenuItem>
    );
});

MenuItem.displayName = 'MenuItem';

export default MenuItem;
