import React from 'react';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';

export type TypographyProps = Omit<MuiTypographyProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Typography component.
 *
 * - [MUI Typography documentation](https://mui.com/material-ui/api/typography/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Typography = React.forwardRef<any, TypographyProps>(({ children, ...props }: TypographyProps, ref) => {
    return (
        <MuiTypography ref={ref} {...props}>
            {children}
        </MuiTypography>
    );
});

Typography.displayName = 'Typography';

export default Typography;
