import React from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import AvatarGroup from 'components/ui-components-v2/AvatarGroup';
import Avatar, { AvatarProps } from 'components/ui-components-v2/Avatar';
import Translation from 'components/data/Translation';
import Icon from 'components/ui-components-v2/Icon';
import { Item } from '../interfaces/Item';

interface MultiAvatarsProps {
    value: Item[];
    avatarSize: Exclude<AvatarProps['size'], 'small'>;
}

const MultiAvatars: React.FC<MultiAvatarsProps> = ({ value, avatarSize }) => {
    if (value.length === 0) {
        return (
            <Tooltip title={Translation.get('actions.unassigned', 'common')}>
                <Avatar size={avatarSize}>
                    <Icon>person_add</Icon>
                </Avatar>
            </Tooltip>
        );
    }
    //Sort user before team to minimize flashing ui when saving
    value.sort((a) => (a.itemType === 'user' ? -1 : 1));
    return (
        <AvatarGroup max={8} size={avatarSize}>
            {value.map((item: Item, index) => (
                <Tooltip title={item.itemType === 'user' ? item.name : item.title} key={index}>
                    <Avatar variant={item.itemType === 'user' ? 'circular' : 'rounded'} src={item.profilePicture}>
                        {item.itemType === 'user' ? item.name : item.title}
                    </Avatar>
                </Tooltip>
            ))}
        </AvatarGroup>
    );
};

export default MultiAvatars;
