import React from 'react';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

type SwitchProps = Omit<MuiSwitchProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Switch component.
 *
 * - [Switch documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/343539713/Switch)
 * - [API documentation](https://mui.com/material-ui/api/switch/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(({ ...props }: SwitchProps, ref) => {
    return <MuiSwitch ref={ref} {...props} />;
});

Switch.displayName = 'Switch';

export default Switch;
