import React from 'react';
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';

type MenuProps = Omit<MuiMenuProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Menu component.
 *
 * - [MUI Menu documentation](https://mui.com/material-ui/api/menu/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Menu = React.forwardRef<HTMLDivElement, MenuProps>(({ children, ...props }: MenuProps, ref) => {
    return (
        <MuiMenu ref={ref} {...props}>
            {children}
        </MuiMenu>
    );
});

Menu.displayName = 'Menu';

export default Menu;
