import React from 'react';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { checkedIcon, icon, indeterminateIcon } from './icons/checkbox-icons';

type CheckboxProps = Omit<MuiCheckboxProps, 'classes' | 'sx' | 'icon' | 'indeterminateIcon' | 'checkedIcon'>;

/**
 * Renders a MUI Button component.
 *
 * - [Checkbox documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/339312642/Checkbox)
 * - [API documentation](https://mui.com/material-ui/api/checkbox/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(({ ...props }: CheckboxProps, ref) => {
    return <MuiCheckbox checkedIcon={checkedIcon} icon={icon} indeterminateIcon={indeterminateIcon} ref={ref} {...props} />;
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
