import React from 'react';
import MuiButtonGroup, { ButtonGroupProps as MuiButtonGroupProps } from '@mui/material/ButtonGroup';

export type ButtonGroupProps = Omit<MuiButtonGroupProps, 'classes' | 'xs'>;

/**
 * Renders a MUI ButtonGroup component.
 *
 * - [Button group documentation](https://mui.com/material-ui/api/button-group/)
 * - [API documentation](https://mui.com/material-ui/api/button-group/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(({ children, ...props }: ButtonGroupProps, ref) => {
    return (
        <MuiButtonGroup ref={ref} {...props}>
            {children}
        </MuiButtonGroup>
    );
});

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
