import React from 'react';
import classNames from 'classnames';
import Typography, { TypographyProps } from '../../ui-components-v2/Typography';
import Avatar, { AvatarProps } from '../../ui-components-v2/Avatar';
import './styles/main.scss';

interface AvatarInfoProps {
    className?: string;
    caption: TypographyProps['children'];
    userName: string;
    userProfilePicture: AvatarProps['src'];
    size?: 'medium' | 'small';
}

/**
 * The AvatarInfo component combines an Avatar and Typography to provide details about the user.
 *
 * - [AvatarInfo documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/367230980/AvatarInfo)
 *
 * @param className - Add class to the root or the component
 * @param userName - The name of the user
 * @param caption - Caption under the username, e.g. a date or time
 * @param userProfilePicture - URL to the user profile picture
 * @param size - The size of the component
 *
 */

const AvatarInfo: React.FC<AvatarInfoProps> = ({ className, caption, userName, userProfilePicture, size = 'medium' }) => {
    return (
        <div className={classNames(`ui-v2-avatar-info ui-v2-avatar-info--${size}`, { [`${className}`]: className })}>
            <Avatar size={size === 'medium' ? 'large' : 'medium'} src={userProfilePicture}>
                {userName}
            </Avatar>
            <div className="ui-v2-avatar-info__text-wrapper">
                <Typography noWrap component="div" variant={size === 'medium' ? 'subtitle2' : 'h6'}>
                    {userName}
                </Typography>
                <Typography className="ui-v2-avatar-info__caption" noWrap component="div" variant="caption">
                    {caption}
                </Typography>
            </div>
        </div>
    );
};

export default AvatarInfo;
