import React from 'react';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';

type SelectProps = Omit<MuiSelectProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Select component.
 *
 * - [Select documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/344162305/Select)
 * - [API documentation](https://mui.com/material-ui/api/select/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Select = React.forwardRef<HTMLInputElement, SelectProps>(({ children, ...props }: SelectProps, ref) => {
    return (
        <MuiSelect ref={ref} {...props}>
            {children}
        </MuiSelect>
    );
});

Select.displayName = 'Select';

export default Select;
