import React from 'react';
import MuiToggleButton, { ToggleButtonProps as MuiToggleButtonProps } from '@mui/material/ToggleButton';
import classNames from 'classnames';

export interface ToggleButtonProps extends Omit<MuiToggleButtonProps, 'classes' | 'sx'> {
    variant?: 'contained';
}

/**
 * Renders a MUI ToggleButton component.
 *
 * - [ToggleButton documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/336036189/ToggleButton)
 * - [API documentation](https://mui.com/material-ui/api/toggle-button/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const ToggleButton = React.forwardRef<HTMLButtonElement, ToggleButtonProps>(({ children, variant, ...props }: ToggleButtonProps, ref) => {
    return (
        <MuiToggleButton
            classes={{
                root: classNames({ ['ui-v2-toggle-button__' + variant]: variant === 'contained', ['ui-v2-toggle-button__text']: typeof children === 'string' })
            }}
            ref={ref}
            {...props}>
            {children}
        </MuiToggleButton>
    );
});

ToggleButton.displayName = 'ToggleButton';

export default ToggleButton;
