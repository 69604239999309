import React from 'react';
import MuiAccordionSummary, { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material/AccordionSummary';

export type AccordionSummaryProps = Omit<MuiAccordionSummaryProps, 'classes' | 'sx'>;

/**
 * Renders a MUI AccordionSummary component.
 *
 * - [MUI AccordionSummary documentation](https://mui.com/material-ui/api/accordion-summary/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */
const AccordionSummary = React.forwardRef<HTMLDivElement, AccordionSummaryProps>(({ children, ...props }: AccordionSummaryProps, ref) => {
    return (
        <MuiAccordionSummary ref={ref} {...props}>
            {children}
        </MuiAccordionSummary>
    );
});

AccordionSummary.displayName = 'AccordionSummary';

export default AccordionSummary;
