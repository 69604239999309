import React from 'react';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

type TooltipProps = Omit<MuiTooltipProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Tooltip component.
 *
 * - [MUI Tooltip documentation](https://mui.com/material-ui/api/tooltip/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(({ children, ...props }: TooltipProps, ref) => {
    return (
        <MuiTooltip ref={ref} {...props}>
            {children}
        </MuiTooltip>
    );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;
