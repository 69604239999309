import React from 'react';
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card';

export type CardProps = Omit<MuiCardProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Card component.
 *
 * - [MUI Card documentation](https://mui.com/material-ui/api/Card/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ children, ...props }: CardProps, ref) => {
    return (
        <MuiCard ref={ref} {...props}>
            {children}
        </MuiCard>
    );
});

Card.displayName = 'Card';

export default Card;
