import React from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';

export type ChipProps = Omit<MuiChipProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Chip component.
 *
 * - [Chip documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/402292842/Chip)
 * - [API documentation](https://mui.com/material-ui/api/chip/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */
const Chip = React.forwardRef<HTMLDivElement, ChipProps>(({ ...props }, ref) => {
    return <MuiChip ref={ref} {...props} />;
});

Chip.displayName = 'Chip';

export default Chip;
