import React from 'react';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import Icon from '../Icon';

type AlertProps = Omit<MuiAlertProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Alert component.
 *
 * - [Alert documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/403374175/Alert)
 * - [API documentation](https://mui.com/material-ui/api/alert/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({ children, ...props }: AlertProps, ref) => {
    return (
        <MuiAlert iconMapping={{ secondary: <Icon>info</Icon> }} ref={ref} {...props}>
            {children}
        </MuiAlert>
    );
});

Alert.displayName = 'Alert';

export default Alert;
