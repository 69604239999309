import React from 'react';
import MuiAccordionDetails, { AccordionDetailsProps as MuiAccordionDetailsProps } from '@mui/material/AccordionDetails';

export type AccordionDetailsProps = Omit<MuiAccordionDetailsProps, 'classes' | 'sx'>;

/**
 * Renders a MUI AccordionDetails component.
 *
 * - [MUI AccordionDetails documentation](https://mui.com/material-ui/api/accordion-details/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */
const AccordionDetails = React.forwardRef<HTMLDivElement, AccordionDetailsProps>(({ children, ...props }: AccordionDetailsProps, ref) => {
    return (
        <MuiAccordionDetails ref={ref} {...props}>
            {children}
        </MuiAccordionDetails>
    );
});

AccordionDetails.displayName = 'AccordionDetails';

export default AccordionDetails;
