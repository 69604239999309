import React from 'react';
import MuiAvatarGroup, { AvatarGroupProps as MuiAvatarGroupProps } from '@mui/material/AvatarGroup';
import './styles/main.scss';

interface AvatarGroupProps extends Omit<MuiAvatarGroupProps, 'classes' | 'sx'> {
    size?: 'medium' | 'large' | 'x-large';
}

/**
 * Renders an MUI AvatarGroup component.
 *
 * - [MUI AvatarGroup documentation](https://mui.com/material-ui/api/avatar-group/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 *
 *  * Custom added props:
 * @param size - Change the size of the avatar group children
 *
 */
const AvatarGroup = React.forwardRef<HTMLDivElement, AvatarGroupProps>(({ size = 'large', children, ...props }: AvatarGroupProps, ref) => {
    return (
        <MuiAvatarGroup ref={ref} classes={{ root: 'ui-v2-avatar-group__' + size }} {...props}>
            {children}
        </MuiAvatarGroup>
    );
});

AvatarGroup.displayName = 'AvatarGroup';

export default AvatarGroup;
