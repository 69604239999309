import React from 'react';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

export type DialogProps = Omit<MuiDialogProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Dialog component.
 *
 * - [MUI Dialog documentation](https://mui.com/material-ui/api/dialog/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(({ children, ...props }: DialogProps, ref) => {
    return (
        <MuiDialog ref={ref} {...props}>
            {children}
        </MuiDialog>
    );
});

Dialog.displayName = 'Dialog';

export default Dialog;
