import React from 'react';
import { SvgIcon } from '@mui/material';

export const indeterminateIcon = (
    <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g filter="url(#filter0_i_1169_66132)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.54546 2.18182C4.13549 2.18182 2.18182 4.13549 2.18182 6.54546V17.4546C2.18182 19.8645 4.13549 21.8182 6.54546 21.8182H17.4546C19.8645 21.8182 21.8182 19.8645 21.8182 17.4545V6.54546C21.8182 4.13549 19.8645 2.18182 17.4545 2.18182H6.54546ZM6.2875 12.7125C6.47917 12.9042 6.71667 13 7 13H17C17.2833 13 17.5208 12.9042 17.7125 12.7125C17.9042 12.5208 18 12.2833 18 12C18 11.7167 17.9042 11.4792 17.7125 11.2875C17.5208 11.0958 17.2833 11 17 11H7C6.71667 11 6.47917 11.0958 6.2875 11.2875C6.09583 11.4792 6 11.7167 6 12C6 12.2833 6.09583 12.5208 6.2875 12.7125Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <filter
                    id="filter0_i_1169_66132"
                    x="2.18182"
                    y="2.18182"
                    width="19.6364"
                    height="19.6364"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1169_66132" />
                </filter>
            </defs>
        </svg>
    </SvgIcon>
);

export const checkedIcon = (
    <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g filter="url(#filter0_i_1168_57192)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.54546 2.18182C4.13549 2.18182 2.18182 4.13549 2.18182 6.54546V17.4546C2.18182 19.8645 4.13549 21.8182 6.54546 21.8182H17.4546C19.8645 21.8182 21.8182 19.8645 21.8182 17.4545V6.54546C21.8182 4.13549 19.8645 2.18182 17.4545 2.18182H6.54546ZM15.9 8.275L9.99999 14.175L8.09999 12.275C7.91665 12.0917 7.68332 12 7.39999 12C7.11665 12 6.88332 12.0917 6.69999 12.275C6.51665 12.4583 6.42499 12.6917 6.42499 12.975C6.42499 13.2583 6.51665 13.4917 6.69999 13.675L9.29999 16.275C9.49999 16.475 9.73332 16.575 9.99999 16.575C10.2667 16.575 10.5 16.475 10.7 16.275L17.3 9.675C17.4833 9.49167 17.575 9.25833 17.575 8.975C17.575 8.69167 17.4833 8.45833 17.3 8.275C17.1167 8.09167 16.8833 8 16.6 8C16.3167 8 16.0833 8.09167 15.9 8.275Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <filter
                    id="filter0_i_1168_57192"
                    x="2.18182"
                    y="2.18182"
                    width="19.6364"
                    height="19.6364"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1168_57192" />
                </filter>
            </defs>
        </svg>
    </SvgIcon>
);

export const icon = (
    <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4546 3.81819H6.54546C5.03923 3.81819 3.81819 5.03923 3.81819 6.54546V17.4546C3.81819 18.9608 5.03923 20.1818 6.54546 20.1818H17.4546C18.9608 20.1818 20.1818 18.9608 20.1818 17.4546V6.54546C20.1818 5.03923 18.9608 3.81819 17.4546 3.81819ZM6.54546 2.18182C4.13549 2.18182 2.18182 4.13549 2.18182 6.54546V17.4546C2.18182 19.8645 4.13549 21.8182 6.54546 21.8182H17.4546C19.8645 21.8182 21.8182 19.8645 21.8182 17.4546V6.54546C21.8182 4.13549 19.8645 2.18182 17.4546 2.18182H6.54546Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
