import React from 'react';
import classNames from 'classnames';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';

export interface AccordionProps extends Omit<MuiAccordionProps, 'classes' | 'sx'> {
    size?: 'small';
}

/**
 * Renders a MUI Accordion component.
 *
 * - [MUI Accordion documentation](https://mui.com/material-ui/api/accordion/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */
const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>(({ children, size, ...props }: AccordionProps, ref) => {
    return (
        <MuiAccordion classes={{ root: classNames({ ['ui-v2-accordion__small']: size === 'small' }) }} ref={ref} {...props}>
            {children}
        </MuiAccordion>
    );
});

Accordion.displayName = 'Accordion';

export default Accordion;
