import React from 'react';
import MuiDivider, { DividerProps as MuiDividerProps } from '@mui/material/Divider';
import './main.scss';

type DividerProps = Omit<MuiDividerProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Divider component.
 *
 * - [Divider documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/403374295/Divider)
 * - [API documentation](https://mui.com/material-ui/api/divider/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Divider = React.forwardRef<HTMLHRElement, DividerProps>(({ children, ...props }: DividerProps, ref) => {
    return (
        <MuiDivider ref={ref} {...props}>
            {children}
        </MuiDivider>
    );
});

Divider.displayName = 'Divider';

export default Divider;
