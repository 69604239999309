import React from 'react';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';

type LinkProps = Omit<MuiLinkProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Link component.
 *
 * - [MUI Link documentation](https://mui.com/material-ui/api/link/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(({ children, ...props }: LinkProps, ref) => {
    return (
        <MuiLink ref={ref} {...props}>
            {children}
        </MuiLink>
    );
});

Link.displayName = 'Link';

export default Link;
