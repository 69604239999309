import React from 'react';
import MuiList, { ListProps as MuiListProps } from '@mui/material/List';

type ListProps = Omit<MuiListProps, 'classes' | 'sx'>;

/**
 * Renders a MUI List component.
 *
 * - [MUI List documentation](https://mui.com/material-ui/api/list/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const List = React.forwardRef<HTMLUListElement, ListProps>(({ children, ...props }: ListProps, ref) => {
    return (
        <MuiList ref={ref} {...props}>
            {children}
        </MuiList>
    );
});

List.displayName = 'List';

export default List;
