import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from 'components/ui-components-v2/Paper';
import Popper from '@mui/material/Popper';
import MenuList from 'components/ui-components-v2/MenuList';
import Icon from 'components/ui-components-v2/Icon';
import Button from '../Button';
import ButtonGroup, { ButtonGroupProps } from '../ButtonGroup';

interface SplitButtonProps {
    menuItems: React.ReactElement[];
    mainButton: React.ReactElement;
    buttonGroupProps?: ButtonGroupProps;
}

/**
 * Renders a SplitButton component.
 *
 * - [SplitButton documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/338034692/SplitButton)
 * - [API documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/338034692/SplitButton#Custom-props)
 *
 * @param mainButton - The main Button component that will render next to the arrow
 * @param menuItems - An array of Menu Items that will be displayed when clicking on the arrow
 * @param buttonGroupProps - An object containing props for the underlying Button Group component
 */

const SplitButton = React.forwardRef<HTMLDivElement, SplitButtonProps>(({ mainButton, menuItems, buttonGroupProps }: SplitButtonProps, ref) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    //Will forward the ref as there is already an anchorRef on the root
    React.useImperativeHandle(ref, () => anchorRef.current as HTMLDivElement);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.MouseEvent<HTMLUListElement>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup {...buttonGroupProps} ref={anchorRef}>
                {mainButton}
                <Button size="small" onClick={handleToggle}>
                    <Icon>arrow_drop_down</Icon>
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    onClick={(event) => {
                                        handleClose(event);
                                    }}
                                    autoFocusItem>
                                    {menuItems.map((item) => {
                                        return item;
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
});

SplitButton.displayName = 'SplitButton';

export default SplitButton;
