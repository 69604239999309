import React from 'react';
import MuiIcon, { IconProps as MuiIconProps } from '@mui/material/Icon';

type IconProps = Omit<MuiIconProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Icon component.
 *
 * - [MUI Icon documentation](https://mui.com/material-ui/api/Icon/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */
const Icon = React.forwardRef<HTMLDivElement, IconProps>(({ ...props }: IconProps, ref) => {
    return <MuiIcon ref={ref} {...props} />;
});

Icon.displayName = 'Icon';

export default Icon;
