import React from 'react';
import MuiSlider, { SliderProps as MuiSliderProps } from '@mui/material/Slider';

export type SliderProps = Omit<MuiSliderProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Slider component.
 *
 * - [Slider documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/341245953/Slider)
 * - [API documentation](https://mui.com/material-ui/api/slider/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Slider = React.forwardRef<HTMLSpanElement, SliderProps>(({ ...props }: SliderProps, ref) => {
    return <MuiSlider ref={ref} {...props} />;
});

Slider.displayName = 'Slider';

export default Slider;
