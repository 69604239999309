import React from 'react';
import DOMPurify from 'dompurify';
import { AutocompleteChangeReason } from '@mui/material';
import { styled } from '@mui/system';
import TextField from 'components/ui-components-v2/TextField';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import Avatar from 'components/ui-components-v2/Avatar';
import Icon from 'components/ui-components-v2/Icon';
import ListItem from 'components/ui-components-v2/ListItem';
import Divider from 'components/ui-components-v2/Divider';
import List from 'components/ui-components-v2/List';
import Typography from 'components/ui-components-v2/Typography';
import Chip from 'components/ui-components-v2/Chip';
import { Item } from '../interfaces/Item';

interface SearchUiProps {
    options: Item[];
    onChange: (event: React.SyntheticEvent, value: any, reason: AutocompleteChangeReason | 'onClearIcon') => void;
    defaultValue: Item | Item[] | undefined;
    multiple?: boolean;
}

const SearchUi: React.FC<SearchUiProps> = ({ options, onChange, defaultValue, multiple }) => {
    const Div = styled('div')({});

    //Checks if the inputValue is contained in the option name (not case sensitive). The returned value is the option.name with the inputValue letters wrapped in a bold class.
    //This function only highlights the first hit, it does not highlight all the o's if there are multiple.
    const highlightInputValueInOption = (inputValue: string, option: Item) => {
        if (!option.name && !option.title) return;
        const optionValue = option.itemType === 'user' ? option.name : option.title;
        if (!optionValue) return;
        const firstIndex = optionValue.toLowerCase().indexOf(inputValue);
        const optionArray = optionValue.split('');
        return optionArray
            .map((x, i) => {
                if (i >= firstIndex && i < firstIndex + inputValue.length) return `<span class="ui-v2-user-selector__bold">${x}</span>`;
                return x;
            })
            .join('');
    };

    return (
        <Autocomplete
            open
            fullWidth
            autoHighlight
            className="ui-v2-user-selector__autocomplete"
            defaultValue={defaultValue}
            multiple={multiple}
            slotProps={{
                paper: { elevation: 0 },
                popper: { className: 'ui-v2-user-selector__list', component: Div, style: { width: '100%' } }
            }}
            getOptionLabel={(option) => {
                if (option.length === 0) return '';
                return option.itemType === 'user' ? option.name : option.title;
            }}
            onChange={(event, value, reason) => {
                if (reason === 'clear' && event.type === 'click') {
                    onChange(event, value, 'onClearIcon');
                    return;
                }
                if (reason === 'clear') return;
                onChange(event, value, reason);
            }}
            isOptionEqualToValue={(option, value) => {
                if (value.length === 0) return false;
                return option.id === value.id;
            }}
            groupBy={(option) => option.itemType}
            getOptionKey={(option) => option.id}
            renderGroup={(params) => {
                return (
                    <li className="ui-v2-user-selector__no-padding" key={params.key}>
                        <List className="ui-v2-user-selector__no-padding">{params.children}</List>
                        {params.group === 'team' && <Divider />}
                    </li>
                );
            }}
            renderOption={(props, option, state) => {
                let highlightedHtmlString;
                const optionName = option.itemType === 'user' ? option.name.toLowerCase() : option.title.toLowerCase();
                const inputValue = state.inputValue.toLowerCase();
                //If the option contains the inputValue, that part will be highlighted in bold
                if (optionName.includes(inputValue)) {
                    highlightedHtmlString = highlightInputValueInOption(inputValue, option);
                }

                const { ...optionProps } = props;
                return (
                    <ListItem
                        secondaryAction={
                            state.selected && (
                                <Icon className="ui-v2-user-selector__list-icon" color="primary">
                                    done
                                </Icon>
                            )
                        }
                        {...optionProps}
                        key={option.id}>
                        <Avatar
                            variant={option.itemType === 'user' ? 'circular' : 'rounded'}
                            className="ui-v2-user-selector__search-avatar"
                            size="large"
                            src={option.profilePicture}>
                            {option.itemType === 'user' ? option.name : option.title}
                        </Avatar>
                        {highlightedHtmlString ? (
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(highlightedHtmlString, { ALLOWED_ATTR: ['class'] })
                                }}
                            />
                        ) : (
                            <Typography variant="body2">{option.itemType === 'user' ? option.name : option.title}</Typography>
                        )}
                    </ListItem>
                );
            }}
            clearIcon={<Icon fontSize="small">delete</Icon>}
            popupIcon={null}
            options={options}
            renderInput={(params) => {
                return <TextField autoFocus {...params} />;
            }}
            renderTags={(value: readonly Item[], getTagProps) =>
                value.map((option: Item, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            avatar={
                                <Avatar src={option.profilePicture} variant={option.itemType === 'user' ? 'circular' : 'rounded'}>
                                    {option.name ? option.name : option.title}
                                </Avatar>
                            }
                            size="small"
                            label={option.name ? option.name : option.title}
                            key={key}
                            {...tagProps}
                        />
                    );
                })
            }
        />
    );
};
export default SearchUi;
