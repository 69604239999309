import React from 'react';
import MuiMenuList, { MenuListProps as MuiMenuListProps } from '@mui/material/MenuList';

type MenuListProps = Omit<MuiMenuListProps, 'classes' | 'sx'>;

/**
 * Renders a MUI MenuList component.
 *
 * - [MUI MenuList documentation](https://mui.com/material-ui/api/menu-list/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const MenuList = React.forwardRef<HTMLUListElement, MenuListProps>(({ children, ...props }: MenuListProps, ref) => {
    return (
        <MuiMenuList ref={ref} {...props}>
            {children}
        </MuiMenuList>
    );
});

MenuList.displayName = 'MenuList';

export default MenuList;
