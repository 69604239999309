import React from 'react';
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';

type BadgeProps = Omit<MuiBadgeProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Badge component.
 *
 * - [MUI Badge documentation](https://mui.com/material-ui/api/badge/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(({ children, ...props }: BadgeProps, ref) => {
    return (
        <MuiBadge ref={ref} {...props}>
            {children}
        </MuiBadge>
    );
});

Badge.displayName = 'Badge';

export default Badge;
