import React from 'react';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';

export type PaperProps = Omit<MuiPaperProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Paper component.
 *
 * - [MUI Paper documentation](https://mui.com/material-ui/api/Paper/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Paper = React.forwardRef<HTMLDivElement, PaperProps>(({ children, ...props }: PaperProps, ref) => {
    return (
        <MuiPaper ref={ref} {...props}>
            {children}
        </MuiPaper>
    );
});

Paper.displayName = 'Paper';

export default Paper;
