import React from 'react';
import MuiStepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper';

export type StepperProps = Omit<MuiStepperProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Stepper component.
 *
 * - [MUI Stepper documentation](https://mui.com/material-ui/api/stepper/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Stepper = React.forwardRef<HTMLDivElement, StepperProps>(({ children, ...props }: StepperProps, ref) => {
    return (
        <MuiStepper ref={ref} {...props}>
            {children}
        </MuiStepper>
    );
});

Stepper.displayName = 'Stepper';

export default Stepper;
