import React from 'react';
import MuiListItem, { ListItemProps as MuiListItemProps } from '@mui/material/ListItem';

type ListItemProps = Omit<MuiListItemProps, 'classes' | 'sx'>;

/**
 * Renders a MUI ListItem component.
 *
 * - [MUI ListItem documentation](https://mui.com/material-ui/api/list-item/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(({ children, ...props }: ListItemProps, ref) => {
    return (
        <MuiListItem ref={ref} {...props}>
            {children}
        </MuiListItem>
    );
});

ListItem.displayName = 'ListItem';

export default ListItem;
