import React from 'react';
import MuiCircularProgress, { CircularProgressProps as MuiCircularProgressProps } from '@mui/material/CircularProgress';

type CircularProgressProps = Omit<MuiCircularProgressProps, 'classes' | 'sx'>;

/**
 * Renders a MUI CircularProgress component.
 *
 * - [CircularProgress documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/402292936/Progress)
 * - [API documentation](https://mui.com/material-ui/api/circular-progress/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const CircularProgress = React.forwardRef<HTMLDivElement, CircularProgressProps>(({ ...props }: CircularProgressProps, ref) => {
    return <MuiCircularProgress ref={ref} {...props} />;
});

CircularProgress.displayName = 'CircularProgress';

export default CircularProgress;
