import React from 'react';
import { IllustrationProps } from '..';

interface IllustrationsProps {
    size: IllustrationProps['size'];
    color: IllustrationProps['color'];
}

const IllustrationBackground = ({ size, color }: IllustrationsProps) => {
    const className = `ui-v2-cape-illustration__background ui-v2-cape-illustration__background--${color}`;
    if (size === 'small') {
        return (
            <svg className={className} xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                <path
                    d="M28 55.5C14.0549 55.5 7.27169 52.2294 3.9133 47.2967C2.22342 44.8147 1.36507 41.8644 0.933225 38.5851C0.500807 35.3014 0.500006 31.7263 0.500006 28C0.500007 24.2737 0.500808 20.6986 0.933227 17.4149C1.36507 14.1355 2.22343 11.1853 3.91331 8.70327C7.27169 3.77064 14.0549 0.5 28 0.500001C41.9451 0.500003 48.7283 3.77065 52.0867 8.70327C53.7766 11.1853 54.6349 14.1356 55.0668 17.4149C55.4992 20.6986 55.5 24.2737 55.5 28C55.5 31.7263 55.4992 35.3014 55.0668 38.5851C54.6349 41.8645 53.7766 44.8147 52.0867 47.2967C48.7283 52.2294 41.9451 55.5 28 55.5Z"
                    fill="currentColor"
                    stroke="#E0E0E0"
                />
            </svg>
        );
    }
    if (size === 'medium') {
        return (
            <svg className={className} xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                <path
                    d="M44 87.5C22.0549 87.5 11.2717 82.3543 5.91331 74.4842C3.22343 70.5335 1.86508 65.8488 1.18323 60.671C0.500812 55.4889 0.500011 49.8513 0.500011 44C0.500012 38.1487 0.500814 32.5111 1.18323 27.3289C1.86508 22.1512 3.22343 17.4665 5.91331 13.5158C11.2717 5.64565 22.0549 0.500002 44 0.500004C65.9451 0.500006 76.7283 5.64565 82.0867 13.5158C84.7766 17.4665 86.1349 22.1512 86.8168 27.329C87.4992 32.5111 87.5 38.1487 87.5 44C87.5 49.8513 87.4992 55.4889 86.8168 60.6711C86.1349 65.8488 84.7766 70.5335 82.0867 74.4842C76.7283 82.3544 65.9451 87.5 44 87.5Z"
                    fill="currentColor"
                    stroke="#E0E0E0"
                />
            </svg>
        );
    }
    if (size === 'large') {
        return (
            <svg className={className} width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M52 103.5C26.0549 103.5 13.2717 97.4168 6.9133 88.078C3.72342 83.3928 2.11507 77.841 1.30822 71.714C0.500804 65.5827 0.500004 58.9138 0.500005 52C0.500005 45.0862 0.500807 38.4173 1.30823 32.286C2.11507 26.159 3.72343 20.6071 6.91331 15.922C13.2717 6.58314 26.0549 0.499993 52 0.499995C77.9451 0.499998 90.7283 6.58314 97.0867 15.922C100.277 20.6072 101.885 26.159 102.692 32.286C103.499 38.4173 103.5 45.0862 103.5 52C103.5 58.9138 103.499 65.5827 102.692 71.714C101.885 77.841 100.277 83.3928 97.0867 88.078C90.7283 97.4169 77.9451 103.5 52 103.5Z"
                    fill="currentColor"
                    stroke="#E0E0E0"
                />
            </svg>
        );
    }
};

export default IllustrationBackground;
