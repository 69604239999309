import React from 'react';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';

import './styles/main.scss';

export interface AvatarProps extends Omit<MuiAvatarProps, 'classes' | 'sx'> {
    size?: 'small' | 'medium' | 'large' | 'x-large';
}

/**
 * Renders an MUI Avatar component.
 *
 * - [Avatar documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/371621892/Avatar)
 * - [API documentation](https://mui.com/material-ui/api/avatar/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 *
 * Custom added props:
 * @param size - The size of the component
 */

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(({ children, size = 'x-large', ...props }: AvatarProps, ref) => {
    const removeSpecialChar = (string: string) => {
        //eslint-disable-next-line
        const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return string
            .split('')
            .filter((initial) => !specialChar.test(initial))
            .join('');
    };

    //Create initials to display when a string is passed to the Avatar
    const createInitials = (fullName: string) => {
        const fullNameArray = fullName.split(' ');
        const firstInitial = fullNameArray[0].slice(0, 1);

        let secondInitial = '';
        const highestIndex = fullNameArray.length - 1;
        if (highestIndex >= 1) {
            secondInitial = fullNameArray[highestIndex].slice(0, 1);
        }

        const initials = firstInitial + secondInitial;
        return removeSpecialChar(initials).toUpperCase();
    };

    const pickRandomPosition = (initials, array) => {
        // Convert letters to their ASCII values
        const ascii1 = initials[0] ? initials[0].charCodeAt(0) : 0;
        const ascii2 = initials[1] ? initials[1].charCodeAt(0) : 0;
        // Combine ASCII values to create a seed
        const seed = ascii1 * 256 + ascii2;
        // Seeded random function
        function seededRandom(seed) {
            const x = Math.sin(seed) * 10000;
            return x - Math.floor(x);
        }
        // Generate a random position using the seed
        const randomPosition = Math.floor(seededRandom(seed) * array.length);
        return randomPosition;
    };

    let initials = '';
    let backgroundColor = '';
    if (typeof children === 'string') {
        //Make initials from the provided string
        initials = createInitials(children);
        //Get avatar bg colors from theme
        const backgroundColorArray = useTheme().palette?.avatar?.backgrounds;
        if (!backgroundColorArray) return;
        //Get a random array position based on the initials, the color array is defined in the theme
        const position = pickRandomPosition(initials, backgroundColorArray);
        backgroundColor = backgroundColorArray[position];
    }
    return (
        <MuiAvatar ref={ref} classes={{ root: 'ui-v2-avatar__' + size }} {...props} sx={{ backgroundColor: backgroundColor ? backgroundColor : null }}>
            {initials ? initials : children}
        </MuiAvatar>
    );
});

Avatar.displayName = 'Avatar';

export default Avatar;
